<template>
  <div
    class="module-common"
    :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]"
  >
    <div class="banner_con">
      <van-swipe
        :show-indicators="false"
        class="guanggao-swipe"
        :autoplay="3000"
        indicator-color="white"
        @change="onSwipeChange"
      >
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <a :href="item.clickUrl" target="_blank">
            <img :src="item.urlPath" />
          </a>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { queryBannerList } from "@/api/index";

export default {
  props: ["module"],

  data() {
    return {
      bannerList: [], // 默认背景
    };
  },
  computed: {
    moduleBgStyle() {
      return {
        background: `linear-gradient(to top,  ${this.module.moduleBg.fzColor} ,${this.module.moduleBg.bgColor} 50% )`,
      };
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = { banType: "MOBILE_2" };
      //主站商品列表
      new Promise((resolve, rejust) => {
        queryBannerList(data)
          .then((res) => {
            this.bannerList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style>
.banner_2 {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
}

.guanggao-swipe img {
  /* 轮播图图片样式 */
  width: 100%;
  height: auto;
  display: block;
}
</style>
